import { clsx } from "clsx";
import { FC, ReactNode, useCallback, useEffect, useMemo, useRef } from "react";
import { RootState } from "@/stores/rootReducer";
import { AnimatePresence, motion } from "framer-motion";
import { useSelector } from "react-redux";
import { tv } from "tailwind-variants";
import IconCheckCircle from "../assets/imgs/svg/icon-check-circle.svg";
import { showSnackbar, hideSnackbar } from "@/stores/snackbar";
import { useDispatch } from "react-redux";
import IconButton from "@/components/IconButton";
import IconWarning from "../assets/imgs/svg/icon-warning.svg";
import IconClose from "../assets/imgs/svg/icon-close.svg";
import useSnackbar from "@/hooks/useSnackbar";

const Snackbar: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const ref = useRef<number>(null);
  const { hidden, message, severity } = useSelector(
    (state: RootState) => state.snackbar,
  );

  const cancelTimer = useCallback(() => {
    if (ref.current) {
      window.clearTimeout(ref.current);
    }
  }, []);

  useEffect(() => {
    if (!hidden) {
      const animate = async () => {
        cancelTimer();
        dispatch(showSnackbar({ message, severity }));
        ref.current = window.setTimeout(() => {
          dispatch(hideSnackbar());
        }, 3000);
      };

      animate();
    }
  }, [hidden, dispatch, message, severity, cancelTimer]);

  return (
    <div
      className={clsx(
        "fixed bottom-full inset-x-0 z-snackbar px-16",
        "grid place-content-center place-items-center",
      )}
    >
      <AnimatePresence>
        {!hidden && (
          <motion.div
            initial={{
              opacity: 0,
              translateY: "calc(100% + 24px)",
            }}
            animate={{
              opacity: 1,
              translateY: "calc(100% + 80px)",
            }}
            exit={{
              opacity: 0,
            }}
          >
            <SnackbarBody message={message} severity={severity} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
export default Snackbar;

const style = tv({
  base: [
    "realative pl-16 pr-12 py-12 rounded-xs",
    " [&_svg]:w-24 [&_svg]:h-24",
    "flex items-center gap-16",
  ],
  variants: {
    severity: {
      error: "bg-danger text-white [&_svg]:fill-white",
      success: "bg-valid text-white [&_svg]:fill-white",
    },
  },
});

export type SnackbarSeverity = "error" | "success";

export interface SnakbarBodyProps {
  message: string;
  severity: SnackbarSeverity;
  tail?: JSX.Element;
}

const SnackbarBody: FC<SnakbarBodyProps> = ({
  message,
  severity,
}): JSX.Element => {
  const Icon = useMemo<ReactNode | undefined>(() => {
    switch (severity) {
      case "error":
        return <IconWarning />;

      case "success":
        return <IconCheckCircle />;
    }
  }, [severity]);

  const { hide } = useSnackbar();

  return (
    <div className={style({ severity })}>
      <div className="grid grid-cols-[24px_1fr] gap-8 items-center">
        {Icon && <>{Icon}</>}
        <div>
          <p className="font-bold text-sm">{message}</p>
        </div>
      </div>

      <IconButton
        variant="ghost"
        size="small"
        onClick={() => hide()}
        aria-label="スナックバーを閉じる"
      >
        <IconClose />
      </IconButton>
    </div>
  );
};
